import moment from 'moment';

export default class CallHistoryController {

  constructor (callHistoryPartyFormatter, $scope, XucUtils, $state, XucPhoneState, CtiProxy, XucCallHistory, $translate, callContext, UserPreferenceService) {
    $scope.history = [];

    $scope.statusImage = function (status) {
      var baseUrl = '/assets/images/ucassistant/call_statut_';
      if (status == 'emitted') return baseUrl + 'outgoing.svg';
      else if (status == 'answered') return baseUrl + 'incoming.svg';
      else if (status == 'missed') return baseUrl + 'missed.svg';
      return '';
    };


    $scope.stopEventAndDial = function (event, number) {
      event.stopPropagation();
      callContext.normalizeDialOrAttTrans(number);
    };

    $scope.stopEventAndCreateContact = (event, number, firstname, lastname) => {
      event.stopPropagation();
      let contact = {
        "firstname": firstname || '',
        "lastname": lastname || '',
        "number": number || ''
      };
      $state.go('interface.personalContact', { "contact": contact });
    };

    $scope.formatHistoryDay = function (date) {
      return moment(date).calendar(null, {
        sameDay: '[' + $translate.instant('CALENDAR_SAME_DAY') + ']',
        lastDay: '[' + $translate.instant('CALENDAR_LAST_DAY') + ']',
        lastWeek: 'dddd [' + $translate.instant('CALENDAR_LAST_WEEK') + ']',
        sameElse: 'DD/MM/YYYY'
      });
    };

    $scope.toggleChildrens = function (item) { 
      item.display = !item.display; 
      UserPreferenceService.resetMissedCalls();
    };

    $scope.showChildrens = function (item) { return item.display; };
    $scope.getDisplayClass = function (item) { return item.display ? "children-show" : "children-hiddens"; };
    $scope.getChevronClass = function (item) { return item.display ? "fa-chevron-up" : "fa-chevron-down"; };

    $scope.init = function () {
      XucCallHistory.subscribeToUserCallHistory($scope, function (callHistory) {
        $scope.history = callHistoryPartyFormatter.toPartyCallsPerDay(callHistory);
      });
      XucCallHistory.updateUserCallHistory();
    };
    $scope.init();
  }

}

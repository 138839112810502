export default function callControl(applicationConfiguration) {

  return {
    restrict: 'E',
    templateUrl: 'ucassistant/callControl.html',
    controller: 'CallControlController',
    link: (scope) => {
      const keyBindings = applicationConfiguration.getCurrentAppConfig().keyBindings;

      const focusSearchBar = (event) => {
        if (keyBindings.has(event.key)) {
          event.preventDefault();
          switch (event.key) {
          case 'F10':
            document.getElementById("search").focus();
            break;
          }
        }
      };

      document.addEventListener("keydown", focusSearchBar);
      scope.$on('$destroy', function() {
        document.removeEventListener("keydown", focusSearchBar);
      });
    }
  };
}

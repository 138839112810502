import 'jquery';
import 'jquery-ui';
import 'angular';
import 'angular-messages';
import 'angular-cookies';
import 'angular-local-storage';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'angular-ui-bootstrap';
import 'angular-ui-slider';
import '@uirouter/angularjs';
import 'angular-material';
import 'angular-material/angular-material.min.css';
import moment from 'moment';

window.moment = moment;

import 'xchelper/helper.module';
import 'xcchat/chat.module';

/* Old libraries */
import 'xccti/cti-webpack';
import 'xclogin/login-webpack';

/* Application */
import config from './assistant.config';
import run from './assistant.run';
import InitController from './controllers/InitController.controller';
import LoginController from './controllers/Login.controller';
import viewController from './controllers/ViewController.controller';
import menuCtrl from './controllers/Menu.controller';
import personalContactCtrl from './controllers/PersonalContact.controller';
import meetingRoomsCtrl from './controllers/MeetingRooms.controller';
import confirmationModalCtrl from './controllers/ConfirmationModal.controller';
import CallMgtModalController from './controllers/CallMgtModal.controller';
import CallMgtMenuController from './controllers/CallMgtMenu.controller';
import CallControlController from './controllers/CallControl.controller';
import CallHistoryController from './controllers/CallHistory.controller';
import ContactsController from './controllers/Contact.controller';
import UserController from './controllers/User.controller';
import forward from './services/forward.factory';
import personalContact from './services/personalContact.factory';
import MeetingRoomService from './services/meetingRoom.service';
import callControl from './directives/callControl.directive';
import conversationController from './controllers/Conversation.controller';
import conversationHistoryController from './controllers/ConversationHistory.controller';
import VoicemailController from './controllers/Voicemail.controller';
import ringtoneDeviceController from "./controllers/RingtoneDevice.controller";
import ringtoneSelectionController from "./controllers/RingtoneSelection.controller";
import deleteMobileAppModalController from "ucassistant/controllers/deleteMobileAppModal.controller";

angular.module('ucAssistant', [
  'xcCti',
  'xcHelper',
  'xcLogin',
  'xcChat',
  'ui.bootstrap',
  'pascalprecht.translate',
  'ui.router',
  'LocalStorageModule',
  'ngMessages',
  'ngMaterial'
]).config(config)
  .controller('LoginController', LoginController)
  .controller('InitController', InitController)
  .controller('MenuController', menuCtrl)
  .controller('PersonalContactController', personalContactCtrl)
  .controller('MeetingRoomsController', meetingRoomsCtrl)
  .controller('ConfirmationModalController', confirmationModalCtrl)
  .controller('ViewController', viewController)
  .controller('CallMgtModalController', CallMgtModalController)
  .controller('CallMgtMenuController', CallMgtMenuController)
  .controller('CallControlController', CallControlController)
  .controller('CallHistoryController', CallHistoryController)
  .controller('ContactsController', ContactsController)
  .controller('UserController', UserController)
  .controller('VoicemailController', VoicemailController)
  .service('forward', forward)
  .service('personalContact', personalContact)
  .service('MeetingRoomService', MeetingRoomService)
  .directive('callControl', callControl)
  .controller('ConversationController', conversationController)
  .controller('ConversationHistoryController', conversationHistoryController)
  .controller('RingtoneDeviceController', ringtoneDeviceController)
  .controller('RingtoneSelectionController', ringtoneSelectionController)
  .controller('deleteMobileAppModalController', deleteMobileAppModalController)
  .run(run);

class UploadAudioQualityReport {
  constructor(RTCOutboundRTPAudioStream, RTCRemoteInboundRtpAudioStream, currentUploadAudioQualityReport = {packetsTotalAccumulator: 0, packetsLostAccumulator: 0}) {
    this.jitter = Math.round(RTCRemoteInboundRtpAudioStream.jitter * 1000);
    this.packetsLost = Math.round(
      (100 * (RTCRemoteInboundRtpAudioStream.packetsLost - currentUploadAudioQualityReport.packetsLostAccumulator)) / 
      (RTCOutboundRTPAudioStream.packetsSent - currentUploadAudioQualityReport.packetsTotalAccumulator)
    );
    this.roundTripTime = Math.round(RTCRemoteInboundRtpAudioStream.roundTripTime * 1000);
    this.packetTotal = `${RTCRemoteInboundRtpAudioStream.packetsLost}/${RTCOutboundRTPAudioStream.packetsSent}`;

    this.packetsTotalAccumulator = RTCOutboundRTPAudioStream.packetsSent;
    this.packetsLostAccumulator = RTCRemoteInboundRtpAudioStream.packetsLost;
  }
}

class DownloadAudioQualityReport {
  constructor(RTCInboundRTPAudioStream, currentDownloadAudioQualityReport = {packetsTotalAccumulator: 0, packetsLostAccumulator: 0}) {
    this.jitter = Math.round(RTCInboundRTPAudioStream.jitter * 1000);
    this.packetsLost = Math.round(
      (RTCInboundRTPAudioStream.packetsLost - currentDownloadAudioQualityReport.packetsLostAccumulator) / 
      ((RTCInboundRTPAudioStream.packetsReceived + RTCInboundRTPAudioStream.packetsLost) - currentDownloadAudioQualityReport.packetsTotalAccumulator) * 100
    );
    this.packetTotal = `${RTCInboundRTPAudioStream.packetsLost}/${RTCInboundRTPAudioStream.packetsReceived + RTCInboundRTPAudioStream.packetsLost}`;
    this.packetsTotalAccumulator = RTCInboundRTPAudioStream.packetsReceived + RTCInboundRTPAudioStream.packetsLost;
    this.packetsLostAccumulator = RTCInboundRTPAudioStream.packetsLost;
  }
}

class MonitoredCall {
  constructor(sipCallId, audioConnection) {
    this.sipCallId = sipCallId;
    this.audioConnection = audioConnection;
    this.audioQualityInterval = undefined;
    this.currentUploadAudioQualityReport = undefined;
    this.currentDownloadAudioQualityReport = undefined;

    this.spikeUpload = {
      jitter: 0,
      packetsLost: 0,
      roundTripTime: 0
    };

    this.spikeDownload = {
      jitter: 0,
      packetsLost: 0,
    };
  }

  getHighest(a, b) {
    if (a > b) return a;
    else return b;
  }

  adjustSpikeValues() {
    this.spikeUpload.jitter = this.getHighest(this.spikeUpload.jitter, this.currentUploadAudioQualityReport.jitter);
    this.spikeUpload.packetsLost = this.getHighest(this.spikeUpload.packetsLost, this.currentUploadAudioQualityReport.packetsLost);
    this.spikeUpload.roundTripTime = this.getHighest(this.spikeUpload.roundTripTime, this.currentUploadAudioQualityReport.roundTripTime);
    this.spikeDownload.jitter = this.getHighest(this.spikeDownload.jitter, this.currentDownloadAudioQualityReport.jitter);
    this.spikeDownload.packetsLost = this.getHighest(this.spikeDownload.packetsLost, this.currentDownloadAudioQualityReport.packetsLost);
  }
}

export default function audioQualityStatistics($rootScope, $interval, $log, $timeout) {

  const AUDIO_QUALITY_EVENT = 'audioQualityEvent';
  const AUDIO_QUALITY_GOOD = 'goodAudioQuality';
  const AUDIO_QUALITY_MEDIUM = 'mediumAudioQuality';
  const AUDIO_QUALITY_BAD = 'badAudioQuality';
  const AUDIO_QUALITY_MONITORING_MS = 2000;
  const AUDIO_QUALITY_LOG_PROPAGATION_MS = 20000;

  const AudioQualityStandards = {
    bad: {
      jitter: 100,
      packetsLost: 20,
      roundTripTime: 300
    },
    medium: {
      jitter: 50,
      packetsLost: 10,
      roundTripTime: 150
    }
  };

  let monitoredCalls = [];
  let logPropagationLock = false;

  const startAudioQualityMonitoring = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    
    if (monitoredCall) {
      initAudioQualityInterval(monitoredCall);
    }
    else {
      let newMonitoredCall = createCallObject(sipCallId);
      monitoredCalls.push(newMonitoredCall);
    }
  };

  const createCallObject = (sipCallId) => {
    let monitoredCall = new MonitoredCall(sipCallId, xc_webrtc.getCurrentRTCPeerConnection(sipCallId));
    initAudioQualityInterval(monitoredCall);
    return monitoredCall;
  };

  const initAudioQualityInterval = (monitoredCall) => {
    if (!monitoredCall.audioQualityInterval) monitoredCall.audioQualityInterval = $interval(getStatistics.bind(null, monitoredCall.sipCallId), AUDIO_QUALITY_MONITORING_MS);
  };
  
  const stopAudioQualityMonitoring = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    if (monitoredCall) {
      $log.info(generateCallReport(monitoredCall), undefined, true);
      $interval.cancel(monitoredCall.audioQualityInterval);
      monitoredCalls.splice(monitoredCalls.findIndex(_ => _.sipCallId == sipCallId), 1);
    }
  };
  
  const getStatistics = (sipCallId) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    monitoredCall.audioConnection.getStats(null).then(processAudioStatistics.bind(null, sipCallId), err => $log.error(err));
  };
  
  const processAudioStatistics = (sipCallId, data) => {
    let monitoredCall = getCallBySipCallId(sipCallId);
    let outboundAudioStream;
    let remoteInboundAudioStream;
    let inboundAudioStream;
    data.forEach(report => {
      if (report.id.includes('RTCOutboundRTPAudioStream') && report.kind == 'audio') outboundAudioStream = report;
      if (report.id.includes('RTCRemoteInboundRtpAudioStream') && report.kind == 'audio') remoteInboundAudioStream = report;
      if (report.id.includes('RTCInboundRTPAudioStream') && report.kind == 'audio') inboundAudioStream = report;
    });
    if (outboundAudioStream && remoteInboundAudioStream && inboundAudioStream && monitoredCall) {
      monitoredCall.currentUploadAudioQualityReport = new UploadAudioQualityReport(outboundAudioStream, remoteInboundAudioStream, monitoredCall.currentUploadAudioQualityReport);
      monitoredCall.currentDownloadAudioQualityReport = new DownloadAudioQualityReport(inboundAudioStream, monitoredCall.currentDownloadAudioQualityReport);
      monitoredCall.adjustSpikeValues();
      processAudioQuality(monitoredCall);
    } 
  };
  
  const getCallBySipCallId = (sipCallId) => {
    return monitoredCalls.find(_ => _.sipCallId == sipCallId);
  };
  
  const processAudioQuality = (call) => {
    let currentCallQuality = getAudioQuality(call);
    if (currentCallQuality == AUDIO_QUALITY_BAD || currentCallQuality == AUDIO_QUALITY_MEDIUM && !logPropagationLock) {
      $log.error(generateAudioStatsReport(call), undefined, true);
      logPropagationLock = true;
      $timeout(() => { logPropagationLock = false; }, AUDIO_QUALITY_LOG_PROPAGATION_MS);
    }

    $rootScope.$broadcast(AUDIO_QUALITY_EVENT, {
      quality: currentCallQuality,
      statistics: {uploadStatistics: call.currentUploadAudioQualityReport, downloadStatistics: call.currentDownloadAudioQualityReport}      
    });
  };

  const generateAudioStatsReport = (monitoredCall) => {
    return `${monitoredCall.sipCallId} Audio quality issues -> ` +
      ` RTT ${monitoredCall.currentUploadAudioQualityReport.roundTripTime}ms ` +
      `- Jitter upstream ${monitoredCall.currentUploadAudioQualityReport.jitter}ms / downstream ${monitoredCall.currentDownloadAudioQualityReport.jitter}ms ` +
      `- Packet loss upstream ${monitoredCall.currentUploadAudioQualityReport.packetsLost}% / downstream ${monitoredCall.currentDownloadAudioQualityReport.packetsLost}% `;
  };

  const generateCallReport = (monitoredCall) => {
    return `${monitoredCall.sipCallId} Call quality report ->` +
    ` Highest RTT ${monitoredCall.spikeUpload.roundTripTime}ms ` +
    `- Highest Jitter upstream ${monitoredCall.spikeUpload.jitter}ms / downstream ${monitoredCall.spikeDownload.jitter}ms ` +
    `- Highest Packet loss upstream ${monitoredCall.spikeUpload.packetsLost}% / downstream ${monitoredCall.spikeDownload.packetsLost}% `;
  };

  const getAudioQuality = (call) => {
    let downloadAudioQuality = getDownloadQuality(call.currentDownloadAudioQualityReport);
    let uploadAudioQuality = getUploadQuality(call.currentUploadAudioQualityReport);

    if (downloadAudioQuality == AUDIO_QUALITY_BAD || uploadAudioQuality == AUDIO_QUALITY_BAD) return AUDIO_QUALITY_BAD;
    if (downloadAudioQuality == AUDIO_QUALITY_MEDIUM || uploadAudioQuality == AUDIO_QUALITY_MEDIUM) return AUDIO_QUALITY_MEDIUM;
    if (downloadAudioQuality == AUDIO_QUALITY_GOOD || uploadAudioQuality == AUDIO_QUALITY_GOOD) return AUDIO_QUALITY_GOOD;
    
  };

  const getDownloadQuality = (downloadReport) => {
    let badStandard = AudioQualityStandards.bad;
    if ( downloadReport.jitter > badStandard.jitter
      || downloadReport.packetsLost > badStandard.packetsLost
    ) return AUDIO_QUALITY_BAD;
    
    let mediumStandard = AudioQualityStandards.medium;
    if ( downloadReport.jitter > mediumStandard.jitter
      || downloadReport.packetsLost > mediumStandard.packetsLost
    ) return AUDIO_QUALITY_MEDIUM;

    return AUDIO_QUALITY_GOOD;
  };

  const getUploadQuality = (uploadReport) => {

    let badStandard = AudioQualityStandards.bad;
    if ( uploadReport.jitter > badStandard.jitter
      || uploadReport.roundTripTime > badStandard.roundTripTime
      || uploadReport.packetsLost > badStandard.packetsLost
    ) return AUDIO_QUALITY_BAD;
    
    let mediumStandard = AudioQualityStandards.medium;
    if ( uploadReport.jitter > mediumStandard.jitter
      || uploadReport.roundTripTime > mediumStandard.roundTripTime
      || uploadReport.packetsLost > mediumStandard.packetsLost
    ) return AUDIO_QUALITY_MEDIUM;

    return AUDIO_QUALITY_GOOD;
  };

  return {
    startAudioQualityMonitoring: startAudioQualityMonitoring,
    stopAudioQualityMonitoring: stopAudioQualityMonitoring,
    AUDIO_QUALITY_EVENT: AUDIO_QUALITY_EVENT,
    AUDIO_QUALITY_BAD: AUDIO_QUALITY_BAD,
    AUDIO_QUALITY_MEDIUM: AUDIO_QUALITY_MEDIUM,
    AUDIO_QUALITY_GOOD: AUDIO_QUALITY_GOOD,
    AUDIO_QUALITY_MONITORING_MS: AUDIO_QUALITY_MONITORING_MS,
    AUDIO_QUALITY_LOG_PROPAGATION_MS: AUDIO_QUALITY_LOG_PROPAGATION_MS
  };
}
